import { useTranslation } from "react-i18next";
import { Button } from "../interactions/Buttons/Button";
import { FC } from "react";
import styles from "./Error.module.scss";

interface Props {
  onClick?: () => void;
  message?: string;
}

export const ServerError: FC<Props> = ({ onClick, message = "Something bad happened. Try again?" }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <h5>{t("Ooh noo")}! 😔</h5>
      <p className={styles.message}>{t(message)}</p>
      {!!onClick && (
        <Button onClick={onClick} block>
          {t("Try again?")}
        </Button>
      )}
    </div>
  );
};
