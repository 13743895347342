import { useState, useEffect, useRef, useCallback, FC } from "react";
import { Status } from "../../data/types";
import { dataContracts } from "../../data/dataContracts";
import { Alternative } from "../interactions/InputTypes";
import { useTranslation } from "react-i18next";
import { getMccOpts, id } from "../utils";
import { ContractType, MccRiskLevel, MerchantCategory } from "../../model/contract/contractType";
import "./MCC.scss";
import { TextInput } from "../form/TextInput";
import { InListValidator } from "../form/validators/InListValidator";
import { RequiredValidator } from "../form/validators/RequiredValidator";
import { Country } from "../../model/common/commonType";
import { Language, TRANSLATION_NAMESPACE } from "../../i18n";
import { getContractTypeDisplayString } from "../../model/contract/contractUtils";
import { WarningBox } from "../boxes/WarningBox";
import { ErrorBox } from "../boxes/ErrorBox";

interface Props {
  status?: Status;
  onChange: (value: string, name: string) => void;
  selectedMccCode: string;
  name: string;
  active: boolean;
  country: Country;
  contractType: ContractType[];
}

export const MCC: FC<Props> = ({
  status = Status.DEFAULT,
  onChange,
  selectedMccCode,
  name,
  active,
  country,
  contractType,
}) => {
  const { t, i18n } = useTranslation();
  const [internal, setInternal] = useState<Status>(Status.PENDING);
  const identifier = useRef<string>(id());

  const [availableMccs, setAvailableMccs] = useState<MerchantCategory[]>([]);
  const [validCodes, setValidCodes] = useState<Set<string>>(new Set());
  const lang = i18n.language as Language;
  const [categories, setCategories] = useState<Alternative<string>[]>(
    getMccOpts([], TRANSLATE_MCC_MAP[lang] ? t : undefined)
  );

  const selectedMCC = availableMccs.find((mcc) => mcc.code === selectedMccCode);

  const change = useCallback((changeValue, changeName) => onChange(changeValue, changeName), [onChange]);

  useEffect(() => {
    dataContracts
      .loadMcc()
      .then((data) => {
        setAvailableMccs(data);

        setValidCodes(
          new Set(
            data
              .filter(({ cardNotPresent, faceToFace }) => faceToFace !== MccRiskLevel.NOT_ALLOWED) // later add logic for mixed with CNP
              .map((mcc) => mcc.code)
          )
        );
        setCategories(getMccOpts(data, TRANSLATE_MCC_MAP[lang] ? t : undefined));
        setInternal(Status.SUCCESS);
      })
      .catch((err) => {
        setInternal(Status.ERROR);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  if (internal === Status.ERROR) {
    return (
      <div>
        <TextInput
          onChange={onChange}
          label={t("MCC")}
          value={selectedMccCode}
          name={name}
          disabled={!active}
          validators={[
            new RequiredValidator(t("MCC is required")),
            new InListValidator(validCodes, t("MCC code is not valid")),
          ]}
        />
      </div>
    );
  }

  return (
    <>
      <div className="mcc-input">
        <TextInput
          onChange={change}
          label={t("MCC")}
          value={selectedMccCode}
          name={name}
          list={identifier.current}
          disabled={!active || internal !== Status.SUCCESS}
          validators={[
            new RequiredValidator(t("MCC is required")),
            new InListValidator(validCodes, t("MCC code is not valid")),
          ]}
        />
        <datalist id={identifier.current}>
          {categories.map((opt) => (
            <option value={opt.value} key={opt.value}>
              {opt.text}
            </option>
          ))}
        </datalist>
      </div>
      <div className={"mcc-additional-info"}>
        <MccInfo merchantCategory={selectedMCC} contractType={contractType} />
      </div>
    </>
  );
};

const TRANSLATE_MCC_MAP: Record<Language, boolean> = {
  [Language.POLAND]: false,
  [Language.CROATIA]: false,
  [Language.UK]: false,
  [Language.FRANCE]: false,
  [Language.CZECH]: true,
};

const shouldTranslateMccName = (language: Language) => {
  const shouldTranslate = TRANSLATE_MCC_MAP[language];
  return shouldTranslate ?? false;
};

type MccValidity = {
  isNotValid: boolean;
  notValidFor: ContractType[];
};

const getMccValidity = (
  faceToFace: MccRiskLevel,
  cardNotPresent: MccRiskLevel,
  contractType: ContractType[],
  mccRiskLevel: MccRiskLevel
) => {
  const result: MccValidity = {
    isNotValid: false,
    notValidFor: [],
  };

  if (faceToFace === mccRiskLevel) {
    result.notValidFor.push(ContractType.INSTORE);
  }

  if (cardNotPresent === mccRiskLevel) {
    result.notValidFor.push(ContractType.ECOMMERCE);
  }

  if (contractType.includes(ContractType.INSTORE) && faceToFace === mccRiskLevel) {
    result.isNotValid = true;
  }

  if (contractType.includes(ContractType.ECOMMERCE) && cardNotPresent === mccRiskLevel) {
    result.isNotValid = true;
  }

  return result;
};

interface MccInfoProps {
  merchantCategory?: MerchantCategory;
  contractType: ContractType[];
}

const MccInfo: FC<MccInfoProps> = ({ merchantCategory, contractType }) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language as Language;

  const mockContractType = [ContractType.ECOMMERCE, ContractType.INSTORE];

  if (!merchantCategory) return null;

  const { faceToFace, cardNotPresent, code, name } = merchantCategory;
  const mccDescription = shouldTranslateMccName(lang) ? t(name, { ns: TRANSLATION_NAMESPACE.MCC }) : name;

  // const needsRiskApproval = getNeedsRiskApproval(faceToFace, cardNotPresent, mockContractType);
  const isNotAllowed = getMccValidity(faceToFace, cardNotPresent, mockContractType, MccRiskLevel.NOT_ALLOWED);
  const needsRiskApproval = getMccValidity(
    faceToFace,
    cardNotPresent,
    mockContractType,
    MccRiskLevel.RISK_APPROVAL
  );

  if (isNotAllowed.isNotValid) {
    return (
      <>
        <b>{code}</b> - <span className="text-passive">{mccDescription}</span>
        <ErrorBox size="small" className="m-top-10">
          {t("Not allowed")}:
          {isNotAllowed.notValidFor.map((type) => (
            <span className="mcc-li-type fw-600" key={type}>{` ${t(
              getContractTypeDisplayString(type)
            )}`}</span>
          ))}
        </ErrorBox>
      </>
    );
  }

  if (needsRiskApproval.isNotValid) {
    return (
      <>
        <b>{code}</b> - <span className="text-passive">{mccDescription}</span>
        <WarningBox size="small" className="m-top-10">
          {t("Needs approval")}:
          {needsRiskApproval.notValidFor.map((type) => (
            <span className="mcc-li-type fw-600" key={type}>{` ${t(
              getContractTypeDisplayString(type)
            )}`}</span>
          ))}
        </WarningBox>
        {/* <Beacon mini validity={Validity.PARTIAL} />  */}
      </>
    );
  }

  return (
    <>
      <b>{code}</b> - <span className="text-passive">{mccDescription}</span>
    </>
  );
};
